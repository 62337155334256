import React from "react"
import Layout from '../components/Layout'
import { Heading } from "@chakra-ui/core"

export default function NotFound() {
  return (
    <Layout>
        <Heading> That page does not exist </Heading>
    </Layout>
  )
}
